import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createNewTransaction, getTransactions } from '../../../../store/transactions';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { validateAddress } from '../../../../utils/networkTools';
import capitalize from '../../../../utils/capitalize';
import { networks } from '../../../../config/select.styles';
import { getBalances, getWalletById } from '../../../../store/wallets';
import { formatNetwork } from '../../../../utils/networkTools';
import { useUser } from '../../../../hooks/useUser';

function NewTransaction() {
  const { id } = useParams();
  const {
    user: { email, role, organization },
  } = useUser();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [managerEmail] = useState(email);
  const [ownerNetworks, setOwnerNetworks] = useState([]);
  const [ownerAddresses, setOwnerAddresses] = useState({
    bitcoin: null,
    ethereum: null,
    avalanche: null,
    polymesh: null,
    stellar: null,
    solana: null,
    ripple: null,
    aptos: null,
    cardano: null,
    polkadot: null,
  });
  const [formData, setFormData] = useState({
    walletId: id,
    orgId: organization,
    requester: email,
    direction: 'withdraw',
    amount: '',
    network: '',
    asset: '',
    destinationAddress: '',
  });

  const wallet = useSelector(state => state?.wallets && state.wallets[id]);
  const balances = useSelector(state => state?.wallets[id]?.balances);
  const currentBalance = getBalanceForAsset(balances, formData.network, formData.asset);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      try {
        await dispatch(getWalletById(controller.signal, id));
      } catch (error) {
        console.error(error);
      }
    })();

    return () => controller.abort();
  }, [dispatch, id, navigate, role]);

  useEffect(() => {
    const controller = new AbortController();
    if (!wallet) {
      return () => setOwnerNetworks([]);
    }
    if (!wallet.active) {
      toast.dismiss();
      toast.error('Wallet has been disabled. Contact an administrator.');
      navigate('/dashboard/wallets');
    }
    const ownerAddresses = {};
    for (const network in wallet.accounts) {
      ownerAddresses[network] = wallet.accounts[network]?.address || null;
    }
    setOwnerAddresses(ownerAddresses);

    const networkSet = new Set();
    for (const network in wallet.accounts) {
      const account = wallet.accounts[network];
      if (!account.active) {
        continue;
      }
      for (const token of account.assets) {
        networkSet.add({
          name: 'network',
          value: token + '-' + network,
          label: `${token.toUpperCase()} (${capitalize(network)})`,
        });
      }
    }

    const networks = Array.from(networkSet);
    setOwnerNetworks(networks);

    if (!balances) {
      dispatch(
        getBalances(controller.signal, {
          walletId: id,
          networks: networks.map(n => n.value),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  function handleSubmit(e) {
    e.preventDefault();
    toast.dismiss();

    if (!balances) {
      toast.error('Please wait for wallet balance to load');
      return;
    }

    try {
      validateFormData(formData, balances);
    } catch (e) {
      toast.error(e);
      return;
    }
    setButtonDisabled(false);
    const load = toast.loading('Creating transaction');
    dispatch(createNewTransaction(formData))
      .then(async res => {
        if (!res || res?.error) {
          toast.error(`Error with submitting new transaction: ${res?.error}`);
          return;
        }
        const { txId } = res;
        await dispatch(getTransactions());

        setTimeout(() => {
          navigate(`/dashboard/withdrawals/${txId}`);
          toast.success('Transaction created successfully!');
        }, 1500);
      })
      .catch(e => {
        toast.error(`Error with creating new transaction: ${e?.message}`);
      })
      .finally(() => {
        toast.dismiss(load);
      });
      setButtonDisabled(true);
  }

  const onChange = e => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

  function onBlur(e) {
    if (currentBalance && formData.network) {
      console.log(currentBalance);
      if (currentBalance === 0) {
        toast.error('Account balance is zero.');
        return;
      }

      if (e.target.value > Number(currentBalance)) {
        toast.error(`Insufficient balance of ${formData.asset.toUpperCase()}.`);
        e.target.value = currentBalance;
        return;
      }
      if (e.target.value <= 0) {
        toast.error('Please enter an amount greater than 0.');
        e.target.value = 0;
        setFormData(prev => ({ ...prev, amount: 0 }));
      }
    }
  }

  const handleSelect = e => {
    const [selectedAsset, selectedNetwork] = e.value.split('-');
    if (balances) {
      const balance = getBalanceForAsset(balances, selectedNetwork, selectedAsset);
      if (balance) {
        if (!parseFloat(balance.replace(/,/g, ''))) {
          toast.error(`${selectedAsset.toUpperCase()} balance is zero.`);
        }
      } else {
        toast.error(`No balance found for ${selectedAsset.toUpperCase()}.`);
      }
    }

    setFormData(prev => ({
      ...prev,
      network: selectedNetwork,
      asset: selectedAsset,
    }));
  };

  const goBack = walletId => {
    if (walletId) {
      navigate(`/dashboard/wallets/${walletId}`);
    } else {
      navigate('/dashboard/wallets');
    }
  };
  const isFormValid = formData.network && formData.amount > 0 && formData.destinationAddress;
  return (
    <div className="flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-gray-800 p-10 rounded-md min-w-fit lg:min-w-[700px]"
      >
        <h1 className="text-3xl font-bold">Create a Withdrawal</h1>
        <div className="py-6">
          <WalletData wallet={wallet} managerEmail={managerEmail} />
        </div>

        <label>Asset</label>
        <div className="pb-6">
          <Select
            name="network"
            className="w-fit min-w-52"
            onChange={handleSelect}
            options={ownerNetworks}
            styles={networks}
            placeholder="Select asset"
          />
        </div>

        <label>Amount</label>
        <div className="flex pb-6">
          <input
            placeholder={'Enter amount'}
            onChange={onChange}
            onBlur={onBlur}
            name="amount"
            type="number"
            value={formData.amount}
            className="flex-1 bg-transparent border-b"
          />
        </div>

        <label>To</label>
        <div className="flex w-full pb-6">
          <input
            onChange={onChange}
            value={formData.destinationAddress}
            name="destinationAddress"
            placeholder="Destination address"
            type="text"
            className="flex-1 bg-transparent border-b"
          />
        </div>

        <FromData balance={currentBalance} fromAddress={ownerAddresses[formData.network]} />

        <div className="w-full flex justify-end space-x-2 pt-8">
        <button
            className={`py-2 px-4 rounded-md disabled:opacity-50 ${isFormValid ? 'bg-green-700 hover:bg-green-500' : 'bg-gray-500 cursor-not-allowed'}`}
            type="submit"
            disabled={!isFormValid || isButtonDisabled}
          >
            Create
          </button>
          <button
            className="py-2 px-4 rounded-md bg-gray-700 hover:bg-gray-500"
            onClick={() => goBack(id)}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

function FromData({ balance, fromAddress }) {
  if (!fromAddress) {
    return null;
  }

  return (
    <>
      <label>From</label>
      <div className="text-xs space-y-2">
        <div className="opacity-60">{fromAddress}</div>
        <div>
          Balance: {Number(balance).toLocaleString('en-US', { maximumSignificantDigits: 21 })}
        </div>
      </div>
    </>
  );
}

function WalletData({ wallet, managerEmail }) {
  return (
    <table className="text-xs">
      <tbody>
        <tr>
          <td className="pr-2">
            <span className="opacity-50">Wallet ID</span>
          </td>
          <td>
            <span>{wallet?.walletId}</span>
          </td>
        </tr>
        <tr>
          <td className="pr-2">
            <span className="opacity-50">Owner</span>
          </td>
          <td>
            <span>{wallet?.owner}</span>
          </td>
        </tr>
        <tr>
          <td className="pr-2">
            <span className="opacity-50">Created by</span>
          </td>
          <td>
            <span>{managerEmail}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function validateFormData(formData, balances) {
  const { amount, asset, network, destinationAddress } = formData;

  const errs = [];
  if (!network) {
    errs.push(new Error('Please select a network'));
  }
  if (!asset) {
    errs.push(new Error('Please select an asset'));
  }

  if (!destinationAddress) {
    errs.push(new Error('Please enter a destination address'));
  } else if (!validateAddress(destinationAddress, network)) {
    errs.push(new Error('Invalid address'));
  }

  if (!amount) {
    errs.push(new Error('Please enter an amount'));
  } else if (amount <= 0) {
    errs.push(new Error(`Amount must be greater than 0; got ${amount}`));
  } else if (amount > balances[formatNetwork(formData.network)]) {
    toast.dismiss();
    errs.push(new Error('The amount entered is greater than the account balance.'));
  }

  if (errs.length > 0) {
    throw new Error(errs.reduce((acc, curr) => `${acc}${curr}\n`, '').trimEnd());
  }
}

function getBalanceForAsset(balances, network, asset) {
  const bal = (Array.isArray(balances) ? balances : []).find(
    balance => balance.asset === asset.toLowerCase() && balance.network === network.toLowerCase(),
  );
  return bal?.balance ?? '0';
}

export default NewTransaction;
