import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { NetworkLogo } from '../../../assets/networks';
import { Loading } from '../../../assets/icons/loading';
import { getBalances, getWalletById, hideAsset } from '../../../store/wallets';
import { shouldShowNetwork } from '../../../utils/assets';
import { CopyToClipboard } from '../../../assets/icons/icons';

const OwnerAccounts = ({ wallet }) => {
  const { walletId } = wallet;
  const balances = useSelector(state => state?.wallets[walletId]?.balances);
  const accounts = useSelector(state => {
    if (state?.wallets[walletId]?.accounts) {
      return Object.keys(state?.wallets[walletId]?.accounts).map(network => ({
        ...state?.wallets[walletId]?.accounts[network],
        network,
      }));
    }
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const getData = async () => {
      try {
        const res = await dispatch(getWalletById(controller.signal, walletId));
        if (res && res.accounts) {
          await dispatch(getBalances(controller.signal, { walletId }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
    return () => {
      controller.abort();
    };
  }, [walletId, dispatch]);

  if (!wallet) {
    return <Loading />;
  }

  if (!accounts || accounts.length === 0) {
    return (
      <>
        <h3 className="text-xl pb-4">Accounts</h3>
        <div className="text-center text-md py-16">Wallet {walletId} has no accounts.</div>;
      </>
    );
  }

  return (
    <div>
      <h3 className="text-xl pb-4">Accounts</h3>
      {accounts.map(acct =>
        acct.assets.map(asset => (
          <Asset walletId={walletId} asset={asset} acct={acct} balances={balances} />
        )),
      )}
    </div>
  );
};

function Asset({ walletId, acct, asset, balances }) {
  const dispatch = useDispatch();

  const handleHideAsset = async (network, asset) => {
    const loading = toast.loading('Working...');
    await dispatch(hideAsset({ walletId, network, asset }));
    toast.dismiss(loading);
  };

  return (
    <div
      key={acct.address + asset}
      className={`flex space-x-6 px-5 text-xs lg:text-sm bg-gradient-to-br from-slate-700 to-slate-800 ${
        !acct.active ? 'bg-red-500' : ''
      }`}
    >
      {/*Network logo, address, and balance*/}
      <div className="flex">
        <div className="flex justify-center max-w-6 max-h-6 lg:max-h-24 lg:max-w-24 p-1 lg:p-5">
          <NetworkLogo network={acct.network} token={asset} size="s" />
        </div>
        <div className="flex items-center">
          <table>
            <tbody>
              <tr>
                <td className="opacity-60 pr-4">
                  <div>Address</div>
                </td>
                <td className="max-w-128 flex space-x-1">
                  <div className="overflow-hidden text-ellipsis">{acct.address}</div>
                  <CopyToClipboard label="Address" text={acct.address} />
                </td>
              </tr>
              <tr>
                <td className="opacity-60 pr-4">
                  <div>Balance</div>
                </td>
                <td>
                  <TokenBalance asset={asset} network={acct.network} balances={balances} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/*Hide asset button*/}
      <div className="flex flex-1 justify-end text-xs">
        <div className="py-2">
          <button
            className="p-2 rounded-md bg-gradient-to-br from-cyan-600 to-cyan-700/75 hover:bg-green-400"
            onClick={() => handleHideAsset(acct.network, asset)}
          >
            Hide Asset
          </button>
        </div>
      </div>
    </div>
  );
}

function TokenBalance({ asset, network, balances }) {
  return useMemo(() => {
    if (!balances) {
      return <div className="text-yellow-300 animate-pulse">Loading...</div>;
    }

    if (balances.error) {
      return <div className="text-yellow-300">No Balance Available</div>;
    }

    const bal = balances.find(x => x.asset === asset);
    if (!bal) {
      return null;
    }
    return (
      <span className="flex space-x-2">
        <span>{Number(bal.balance).toLocaleString('en-US', { maximumSignificantDigits: 21 })}</span>
        <span>{asset.toUpperCase()}</span>
        <span className="opacity-80" hidden={!shouldShowNetwork({ asset })}>
          ({network.toUpperCase()})
        </span>
      </span>
    );
  }, [asset, balances, network]);
}

export default OwnerAccounts;
