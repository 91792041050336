import OrganizationFilter from '../../../utils/OrganizationFilter';
import CSVDownload from '../../actions/CSVDownload';

import JSONFileDownload from '../../actions/JSONFileDownload';
import { OrganizationBanner } from '../../misc/AdminAccessBanner';
import { useUser } from '../../../hooks/useUser';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

export type TransactionsHeaderProps = {
  transactions: Record<string, any>;
  organizationVisibility: any;
  setOrganizationVisibility: any;
  filter: any;
  setFilter: any;
  jump: any;
};

export const TransactionsHeader = ({
  transactions,
  organizationVisibility,
  setOrganizationVisibility,
}: TransactionsHeaderProps) => {
  const { user } = useUser();
  const [hover, setHover] = useState(false);

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-2xl text-gray-100 font-montserratM">Withdrawals</h1>
        <OrganizationFilter
          hidden={!user.groups.includes('superadmins')}
          organizationVisibility={organizationVisibility}
          setOrganizationVisibility={setOrganizationVisibility}
        />
        <div className="flex space-x-2">
          <InformationCircleIcon
            className="h-5 w-5 mt-1 mr-1 text-gray-500"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
          {hover && (
            <div className="absolute right-10 m-10 bg-white text-black p-2 rounded shadow-lg w-max">
              Downloading this type of document in CSV format may result in data loss
            </div>
          )}
          <CSVDownload title="transactions" documents={transactions} />
          <JSONFileDownload title="transactions" data={transactions} />
        </div>
      </div>
      <OrganizationBanner user={user} />
    </>
  );
};

export default TransactionsHeader;
