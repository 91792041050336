import { BuildingLibraryIcon } from '@heroicons/react/24/outline';

export const OrganizationBanner = ({ user }) => (
  <span className="flex items-center space-x-2">
    <span className="opacity-65 font-semibold">{user?.organization}</span>
    <span>{adminAccessBanner(user)}</span>
  </span>
);

export const AdminAccessBanner = ({ title }) => (
  <div className="flex text-green-500 space-x-2 items-center">
    <BuildingLibraryIcon className="w-6" />
    <span>{title}</span>
  </div>
);

export function adminAccessBanner(user) {
  if (user?.groups.includes('superadmins')) {
    return <AdminAccessBanner title="Super Admin" />;
  }
  if (user?.groups.includes('admins')) {
    return <AdminAccessBanner title="Admin" />;
  }
  if (user?.groups.includes('developers')) {
    return <AdminAccessBanner title="Developer" />;
  }
  return null;
}
