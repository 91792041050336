import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';
import { currentComponent, showModal } from '../../../store/modal';
import DepositCard from './DepositCard';
import { useState } from 'react';
import { AssetAmount } from '../../../assets/AssetAmount';

export default function DepositListItem({ data, searchQuery }) {
  const dispatch = useDispatch();
  const viewDetail = data => {
    dispatch(currentComponent(() => <DepositCard deposit={data} />));
    dispatch(showModal());
  };

  return (
    <tr
      onClick={() => viewDetail(data)}
      className="h-10 text-xs leading-none text-white cursor-pointer hover:bg-[#FFFFFF10] border-b border-t border-dotted border-gray-400"
    >
      <td></td>
      <td className="py-3">
        <Highlighter
          highlightClassName={'HighlightActive'}
          textToHighlight={data.walletId}
          searchWords={[searchQuery]}
          caseSensitive={false}
          autoEscape={true}
        />
      </td>
      <td className="pl-4">
        <AssetAmount asset={data.asset} network={data.network} amount={data.amount} />
      </td>
      <td className="pl-4">{new Date(data.seenAt).toLocaleString()}</td>
      <td className="pl-4">
        <div className="flex items-center max-w-[350px]">
          <Highlighter
            highlightClassName="HighlightActive"
            textToHighlight={data?.originAddress || data?.originAddresses[0]}
            className="overflow-hidden text-ellipsis"
            searchWords={[searchQuery]}
            caseSensitive={false}
            autoEscape={true}
          />
          <ExtraOriginAddresses data={data} />
        </div>
      </td>
    </tr>
  );
}

/**
 * @param {any} data
 * @returns {JSX.Element|null}
 * @constructor
 */
function ExtraOriginAddresses({ data }) {
  const defaultShowCount = 1;
  const [hover, setHover] = useState(false);
  const extraOriginsExist = Boolean(data?.originAddresses?.length > defaultShowCount);

  if (!extraOriginsExist) {
    return null;
  }

  return (
    <div>
      <span
        className="cursor-pointer text-[#22c55e] underline pl-2"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        and {data.originAddresses.length - defaultShowCount} more
      </span>
      {/*Display extra addresses on hover*/}
      <div
        className={`rounded absolute left-3/4 -translate-x-1/2 bg-white text-black p-5 ${hover && extraOriginsExist ? '' : 'hidden'}`}
      >
        {data.originAddresses.slice(defaultShowCount).map((origin, index) => (
          <div key={index}>{origin}</div>
        ))}
      </div>
    </div>
  );
}
