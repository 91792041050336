import { TxStatus } from '../../../assets/icons/icons';
import { txApproval, txReject, pendingApproval } from '../../../config/tailwind.classnames';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';
import { currentComponent, hideModal, showModal } from '../../../store/modal';
import { TransactionCard } from './TxCard';
import { AssetAmount } from '../../../assets/AssetAmount';

export default function TransactionListItem({ txData, searchQuery }) {
  const dispatch = useDispatch();
  function viewTx() {
    dispatch(
      currentComponent(() => (
        <TransactionCard transaction={txData} onClose={() => dispatch(hideModal())} />
      )),
    );
    dispatch(showModal());
  }

  const approvalStatus = txData?.approval.status;
  const txStatus = txData?.txProcessingStatus;

  return (
    <tr
      onClick={viewTx}
      className="cursor-pointer hover:bg-[#FFFFFF10] border-b border-t border-dotted border-gray-400"
    >
      <TxStatus status={txStatus} />
      <td className="py-1 pr-4 pl-1">
        <Highlighter
          highlightClassName={'HighlightActive'}
          textToHighlight={txData.txId}
          searchWords={[searchQuery]}
          caseSensitive={false}
          autoEscape={true}
        />
      </td>
      <td className="py-1 pr-4">
        <Highlighter
          highlightClassName={'HighlightActive'}
          textToHighlight={txData.walletId}
          searchWords={[searchQuery]}
          caseSensitive={false}
          autoEscape={true}
        />
      </td>
      <td className="py-1 pr-4">
        <AssetAmount asset={txData.asset} network={txData.network} amount={txData.amount} />
      </td>
      <td className="py-1 pr-4">{new Date(txData.dateCreated).toLocaleString()}</td>
      <ApprovalIcon approvalStatus={approvalStatus} txStatus={txStatus} />
    </tr>
  );
}

function ApprovalIcon({ approvalStatus, txStatus }) {
  if (approvalStatus === 'approved') {
    return (
      <td className={`${txApproval} ${txStatus === 'failure' && 'opacity-25'}`}>
        <div className="flex justify-center">
          <CheckIcon className="w-5" />
        </div>
      </td>
    );
  }
  if (approvalStatus === 'rejected') {
    return (
      <td className={txReject}>
        <div className="flex justify-center">
          <XMarkIcon className="w-5" />
        </div>
      </td>
    );
  }
  return <td className={pendingApproval} />;
}
